body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

strong {
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 100vh;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 60vw;
  height: auto;
  animation-name: spinning-circle;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes spinning-circle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinning-circle {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.imgr {
  -webkit-animation: 3s rotate linear infinite;
  animation: 3s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}